import React from "react";
import styled from 'styled-components';

import './style.css'

const StyledText = styled.span`
    float: left;
    font-family: 'DINNextLTPro-Condensed';
    margin: -2px 0 0 8px;
    font-size: 18px;
    color: #df5828;
`

const BurgerIcon = ({ open, color, clickHandler, ...props }) => (
  <a className={open ? "burger-menu open" : "burger-menu"} role='button' tabIndex={0} onClick={clickHandler} onKeyDown={clickHandler} {...props}>
        <div style={{float: 'left'}}>
            <div style={{backgroundColor: open ? '#fff' : color}} className="bar1" key="b1" />
            <div style={{backgroundColor: open ? '#fff' : color}} className="bar2" key="b2" />
            <div style={{backgroundColor: open ? '#fff' : color}} className="bar3" key="b3" />  
        </div>
        {
            !open && <StyledText style={{color: color}}>menu</StyledText>
        }
  </a>
);

export default BurgerIcon
