import React from "react"
import { Link, } from 'gatsby'
import styled from 'styled-components';

import Header from './logo-header.png'

const StyledContainer = styled.div`
  width: ${props => props.width + 'px'};
  height: ${props => props.height + 'px'};
  max-width: ${props => props.width + 'px'};

  @media only screen and (max-width: 1000px) {
    width: ${props => props.mobileWidth + 'px'};
    height: ${props => props.mobileHeight + 'px'};
    max-width: ${props => props.mobileWidth + 'px'};
  }
`;

const Logotype = ({ width, height, mobileWidth, mobileHeight, variant, link, ...props }) => {
    let image = null
    switch(variant) {
        case 'header':
            image = Header
            break;
        default:
            image = ''
            break;
    }
    mobileWidth = typeof mobileWidth === 'undefined' ? width : mobileWidth;
    mobileHeight = typeof mobileHeight === 'undefined' ? height : mobileHeight;

    const imageElement = <img src={image} alt="PEDLEY LAW Logotype" style={{margin: '0'}} />;

    return(
        <>
            {
                link ? 
                <Link to="/" style={{width: width, display: 'inline-block', margin: '0'}}>
                    <StyledContainer width={width} mobileWidth={mobileWidth} height={height} mobileHeight={mobileHeight} {...props}>
                        {imageElement}
                    </StyledContainer>
                </Link> :
                <StyledContainer width={width} mobileWidth={mobileWidth} height={height} mobileHeight={mobileHeight} {...props}>
                    {imageElement}
                </StyledContainer>
            }
        </>
    )
}

export default Logotype