import React, { useState } from "react";
import styled from 'styled-components';

import Logotype from '../../atoms/Logotype'
import BurgerIcon from "../../atoms/BurgerIcon";
import NavigationList from '../../atoms/NavigationList'

const StyledNav = styled.nav`
    background: #f5f4ec;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1;
    width: 100%;
`;

const Navigation = () => {
    const logotypeSize = 300
    const [open, setOpen] = useState(false);

    const clickHandler = () => {
        setOpen(!open);
    }

    return (
        <StyledNav>
            <Logotype width={logotypeSize} height='61' variant='header' link={true} />
            <BurgerIcon color={'#df5828'} open={open} clickHandler={clickHandler} />
            <NavigationList open={open} clickHandler={clickHandler} screenSize='small' />
        </StyledNav>
    )
}

export default Navigation