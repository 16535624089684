import React from "react";
import styled from "styled-components"
import { Link } from "gatsby"

const StyledList = styled.ul`
    border: none;
    box-sizing: border-box;
    background: rgb(222,88,39, 0.98);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    right: 0px;
    margin: auto 0px;
    overflow: hidden;
    overflow-y: hidden;
    padding: 32px;
    position: absolute;
    transform: translate3d(0, ${props => props.open ? '0' : '-100%'}, 0);
    transition: all .25s ease-out;
    top: 0px;
    width: 230px;
    z-index: 999;
`;

const StyledListItem = styled.li`
    border-bottom: 1px solid #fff;
    list-style: outside none none;
    line-height: 1.8;
    margin: 4px 0;
    padding: 8px;
    position: relative;
    cursor: pointer;
`;

const StyledLink = styled(Link)`
    font-family:'DINNextLTPro-Light';
    font-size: 16px;
    color: #fff;
    text-decoration: none;

    &:visited,
    &:hover,
    &:active,
    &:focus,
    &:focus-within,
    &:link:active,
    &:visited:active {
        color: #fff;
    }
`


const NavigationList = ({ open, clickHandler, screenSize }) => {
    const childItems = (screenSize) => {
        return (
            <>
                <StyledListItem onClick={clickHandler} size={screenSize}>
                    <StyledLink to="/">Home</StyledLink>
                </StyledListItem>
                <StyledListItem onClick={clickHandler} size={screenSize}>
                    <StyledLink to="/about-us/">About us</StyledLink>
                </StyledListItem>
                <StyledListItem onClick={clickHandler} size={screenSize}>
                    <StyledLink to="/attorneys/">Attorneys</StyledLink>
                </StyledListItem>
                <StyledListItem onClick={clickHandler} size={screenSize}>
                    <StyledLink to="/transactions/">Transactions</StyledLink>
                </StyledListItem>
                <StyledListItem onClick={clickHandler} size={screenSize}>
                    <StyledLink to="/contact-us/">Contact us</StyledLink>
                </StyledListItem>
            </>
        )
    }

    return (
        <StyledList open={open}>
            {childItems(screenSize)}
        </StyledList>
    )
};

export default NavigationList
