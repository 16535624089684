import React from "react"
import styled from 'styled-components';

import Navigation from '../../molecules/Navigation'

const StyledHeader = styled.header`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
`;

const Header = ({ children }) => {
    const navigation =
        <>
            <StyledHeader>
                <Navigation />
                {children}
            </StyledHeader>
        </>

    return (
        <>
            {navigation}
        </>
    )
}

export default Header
